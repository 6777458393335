.company-registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  min-height: 100vh;
  background-color: #f9f9f9;
}

.company-registration-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  padding: 2rem;
}

.company-registration-card h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #333;
  text-align: center;
}

.info-text {
  text-align: center;
  color: #666;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #333;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #f2c94c;
  outline: none;
  box-shadow: 0 0 0 2px rgba(242, 201, 76, 0.2);
}

.submit-button {
  width: 100%;
  padding: 14px;
  background-color: #f2c94c;
  color: #333;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button:hover {
  background-color: #e6b93b;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  background-color: rgba(235, 87, 87, 0.1);
  color: #eb5757;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.debug-info {
  margin: 15px 0;
  padding: 15px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: left;
  max-height: 200px;
  overflow-y: auto;
}

.debug-info h4 {
  margin-top: 0;
  color: #333;
}

.debug-info pre {
  margin: 0;
  white-space: pre-wrap;
  font-size: 12px;
  font-family: monospace;
} 