/* Global Styles */
.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Dashboard Layout */
.dashboard {
  text-align: left;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
}

.dashboard-header {
  background-color: #ffffff;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.dashboard-header h1 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
}

.dashboard-content {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.welcome-section {
  margin-bottom: 2rem;
  background-color: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.welcome-section h2 {
  margin-top: 0;
  color: #333;
}

.company-section {
  margin-bottom: 2rem;
}

.company-section h2 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #333;
}

/* Authentication */
.logout-button {
  background-color: #f2c94c;
  color: #333;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 30px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.logout-button:hover {
  background-color: #e6b93b;
  transform: translateY(-2px);
}

.api-message {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgba(242, 201, 76, 0.1);
  border-radius: 8px;
}

/* Loading and Error States */
.loading,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  flex-direction: column;
  color: #333;
  padding: 2rem;
}

.error {
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.error h2 {
  color: #e74c3c;
  margin-bottom: 20px;
}

.error p {
  margin-bottom: 20px;
  color: #555;
}

.error-actions {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
}

.error-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.error-actions button:first-child {
  background-color: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
}

.error-actions button:last-child {
  background-color: #00b4d8;
  color: white;
}

.error-steps {
  text-align: left;
  margin: 20px auto;
  max-width: 80%;
  color: #555;
}

.error-steps li {
  margin-bottom: 8px;
}

/* Media Queries */
@media (max-width: 768px) {
  .dashboard-header {
    flex-direction: column;
    padding: 1rem;
    text-align: center;
  }
  
  .dashboard-header h1 {
    margin-bottom: 1rem;
  }
  
  .dashboard-content {
    padding: 1rem;
  }
}

/* Main Layout Styles */
.main-layout {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 240px;
  background-color: #1a1a2e;
  color: white;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-header h2 {
  margin: 0;
  color: #00b4d8;
}

.sidebar-nav {
  flex: 1;
  padding: 20px 0;
}

.sidebar-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-nav li {
  margin-bottom: 5px;
}

.sidebar-nav a, .sidebar-nav .nav-link {
  display: block;
  padding: 12px 20px;
  color: #e0e0e0;
  text-decoration: none;
  transition: all 0.2s;
}

.sidebar-nav a:hover, .sidebar-nav .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.sidebar-footer {
  padding: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-footer button {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.sidebar-footer button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.main-content {
  flex: 1;
  margin-left: 240px;
  padding: 20px;
}

.main-header {
  padding: 15px 0;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
}

.user-info {
  font-weight: 500;
}

/* Dashboard Page */
.dashboard-page {
  padding: 20px 0;
}

.dashboard-welcome {
  margin-bottom: 30px;
}

.dashboard-overview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.overview-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.overview-card h3 {
  margin-top: 0;
  color: #333;
}

.card-content {
  padding: 20px 0;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #00b4d8;
}

.stat-label {
  font-size: 0.9rem;
  color: #666;
  margin-top: 5px;
}

.card-footer {
  padding-top: 15px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
}

.active {
  color: #2ecc71;
}

.inactive {
  color: #e74c3c;
}

.card-link {
  color: #00b4d8;
  text-decoration: none;
}

.card-link:hover {
  text-decoration: underline;
}

.dashboard-shortcuts {
  margin-top: 30px;
}

.shortcuts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.shortcut-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-decoration: none;
  color: inherit;
  transition: all 0.2s;
}

.shortcut-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.shortcut-card h3 {
  margin-top: 0;
  color: #00b4d8;
}

.admin-card h3 {
  color: #9c27b0;
}

/* Telematics Page */
.telematics-page {
  padding: 20px 0;
}

.telematics-admin-panel {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 30px;
}

.provider-connection {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
}

.provider-select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.connect-button {
  padding: 8px 16px;
  background-color: #00b4d8;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.provider-connected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #e8f7ff;
  border-radius: 4px;
  margin-top: 15px;
}

.telematics-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.mock-map {
  background-color: #eee;
  height: 400px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.map-placeholder {
  text-align: center;
  color: #666;
}

.vehicle-list {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.vehicle-list table {
  width: 100%;
  border-collapse: collapse;
}

.vehicle-list th, .vehicle-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.vehicle-list th {
  font-weight: 600;
  color: #333;
}

/* Communications Page */
.communications-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.communications-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.new-conversation-button {
  background-color: #4a6fd6;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
}

.new-conversation-button:hover {
  background-color: #3a5cc0;
}

.loading-indicator {
  color: #666;
  font-style: italic;
}

.error-message {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  border-left: 4px solid #d32f2f;
}

.new-conversation-form {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.new-conversation-form input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.new-conversation-form button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.new-conversation-form button:hover {
  background-color: #388e3c;
}

.communications-container {
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 20px;
  height: 70vh;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
}

.contacts-list {
  border-right: 1px solid #ddd;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.contacts-list h3 {
  padding: 15px;
  margin: 0;
  background-color: #4a6fd6;
  color: white;
}

.contacts-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.contact-item {
  padding: 15px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.contact-item:hover {
  background-color: #f0f0f0;
}

.contact-item.selected {
  background-color: #e3f2fd;
  border-left: 4px solid #4a6fd6;
}

.contact-info {
  display: flex;
  flex-direction: column;
}

.contact-info strong {
  margin-bottom: 5px;
}

.contact-info span {
  font-size: 0.9em;
  color: #666;
}

.unread-badge {
  background-color: #e91e63;
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
}

.message-area {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.message-header {
  padding: 15px;
  border-bottom: 1px solid #eee;
  background-color: #f9f9f9;
}

.message-header h3 {
  margin: 0 0 5px;
}

.message-header p {
  margin: 0;
  color: #666;
  font-size: 0.9em;
}

.message-list {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.no-messages, .no-conversations {
  color: #666;
  font-style: italic;
  text-align: center;
  margin-top: 20px;
}

.message {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}

.message.sent {
  align-self: flex-end;
}

.message.received {
  align-self: flex-start;
}

.message-bubble {
  padding: 12px;
  border-radius: 18px;
  margin-bottom: 2px;
  word-wrap: break-word;
}

.message.sent .message-bubble {
  background-color: #4a6fd6;
  color: white;
  border-bottom-right-radius: 5px;
}

.message.received .message-bubble {
  background-color: #e9e9e9;
  color: #333;
  border-bottom-left-radius: 5px;
}

.message-time {
  font-size: 0.75em;
  color: #999;
  align-self: flex-end;
}

.message.sent .message-time {
  margin-right: 5px;
}

.message.received .message-time {
  align-self: flex-start;
  margin-left: 5px;
}

.message-composer {
  padding: 15px;
  border-top: 1px solid #eee;
  display: flex;
  gap: 10px;
}

.message-composer input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 20px;
}

.message-composer button {
  background-color: #4a6fd6;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.message-composer button:hover {
  background-color: #3a5cc0;
}

.message-composer button:disabled,
.new-conversation-form button:disabled {
  background-color: #b0bec5;
  cursor: not-allowed;
}

.no-contact-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
  font-style: italic;
}

/* Admin Dashboard */
.admin-dashboard {
  padding: 20px 0;
}

.admin-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.tab-button {
  padding: 10px 20px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #666;
}

.tab-button.active {
  background-color: #00b4d8;
  color: white;
}

.admin-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input, .form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.upload-note {
  color: #666;
  font-style: italic;
  margin-bottom: 15px;
}

.file-upload {
  margin-bottom: 20px;
}

.upload-status {
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.upload-status.success {
  background-color: #e7f9ed;
  color: #2ecc71;
}

.upload-status.error {
  background-color: #fdeded;
  color: #e74c3c;
}

.personnel-list {
  margin-top: 30px;
}

.personnel-list table {
  width: 100%;
  border-collapse: collapse;
}

.personnel-list th, .personnel-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.personnel-list th {
  font-weight: 600;
  color: #333;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  
  .main-content {
    margin-left: 0;
  }
  
  .communications-container {
    grid-template-columns: 1fr;
  }
  
  .overview-card, .shortcut-card {
    min-width: 100%;
  }
}

/* DIMO Integration Styles */
.provider-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dimo-login-container {
  margin-bottom: 20px;
}

.dimo-login-container h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

.dimo-login-container p {
  margin-bottom: 10px;
}

.dimo-connected-message {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #e6f7e6;
  border-radius: 4px;
  color: #2e7d32;
}

/* Provider connection styles */
.provider-connection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.provider-select {
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.connect-button {
  padding: 8px 16px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.connect-button:hover {
  background-color: #357abD;
}

.connect-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.provider-connected {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.provider-connected button {
  padding: 6px 12px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.provider-connected button:hover {
  background-color: #d32f2f;
}

/* Telematics page layout */
.telematics-admin-panel {
  margin-bottom: 30px;
}

/* DIMO error message */
.dimo-error-message {
  margin: 10px 0 15px;
  padding: 10px 15px;
  background-color: #ffebee;
  border-radius: 4px;
  color: #d32f2f;
  border-left: 4px solid #f44336;
}

.dimo-error-message p {
  margin: 5px 0;
}

/* Message area header with actions */
.conversation-actions {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.action-button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #357abD;
}

.archive-button {
  background-color: #e57373;
}

.archive-button:hover {
  background-color: #ef5350;
}

/* Add participant form */
.add-participant-form {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.add-participant-form input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.add-participant-form button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.add-participant-form button:hover {
  background-color: #43a047;
}

.add-participant-form button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Participants list styles */
.participants-list {
  margin-top: 5px;
  font-size: 14px;
  padding: 5px 0;
  border-top: 1px solid #eee;
}

.participants-list p {
  margin: 0;
  color: #666;
}
