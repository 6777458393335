.admin-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.admin-dashboard h1 {
  color: #333;
  margin-bottom: 30px;
  font-size: 32px;
}

.admin-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 10px;
}

.tab-button {
  padding: 10px 20px;
  background: transparent;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  color: #4a5568;
}

.tab-button:hover {
  background-color: #f7fafc;
  color: #4a90e2;
}

.tab-button.active {
  background-color: #4a90e2;
  color: white;
}

.admin-content {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 20px;
  min-height: 400px;
}

@media (max-width: 768px) {
  .admin-dashboard {
    padding: 15px;
  }
  
  .admin-tabs {
    flex-wrap: wrap;
  }
  
  .tab-button {
    flex: 1;
    min-width: 120px;
    text-align: center;
    padding: 8px 10px;
    font-size: 14px;
  }
  
  .admin-content {
    padding: 15px;
  }
} 