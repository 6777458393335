/* Telematics Page Styles */
.telematics-page {
  padding: 20px;
}

.loading-container {
  text-align: center;
  padding: 40px;
  font-size: 18px;
  color: #666;
}

.error-container, .no-connection-container {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 30px;
  margin: 20px 0;
  text-align: center;
}

.error-message {
  color: #d32f2f;
  font-size: 16px;
}

.no-connection-message {
  color: #666;
}

.no-connection-message h3 {
  margin-bottom: 15px;
  color: #333;
}

.connect-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.connect-button:hover {
  background-color: #1976d2;
}

.connect-hint {
  margin-top: 15px;
  font-size: 14px;
  color: #666;
  font-style: italic;
}

.connection-status {
  margin-bottom: 20px;
}

.connected-status {
  background-color: #e8f5e9;
  padding: 10px 15px;
  border-radius: 4px;
  color: #2e7d32;
  display: inline-block;
}

.vehicle-map-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
}

.mock-map {
  background-color: #eee;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-placeholder {
  text-align: center;
  padding: 20px;
}

.vehicle-list {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.vehicle-list h3 {
  padding: 15px 20px;
  margin: 0;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.vehicle-list table {
  width: 100%;
  border-collapse: collapse;
}

.vehicle-list th {
  text-align: left;
  padding: 12px 15px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
}

.vehicle-list td {
  padding: 12px 15px;
  border-bottom: 1px solid #eee;
}

.vehicle-list tr:last-child td {
  border-bottom: none;
}

.vehicle-id {
  font-size: 12px;
  color: #777;
  margin-top: 5px;
}

.no-vehicles-message {
  text-align: center;
  padding: 30px;
  color: #666;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 20px 0;
}

/* Telematics Connect Component Styles */
.telematics-connect {
  padding: 20px 0;
}

.providers-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.provider-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.provider-card h3 {
  margin-top: 0;
  margin-bottom: 15px;
  color: #333;
}

.dimo-login-container {
  margin-bottom: 10px;
}

.dimo-error-message {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 10px 15px;
  border-radius: 4px;
  margin: 10px 0;
}

.dimo-connected-message {
  background-color: #e8f5e9;
  color: #2e7d32;
  padding: 10px 15px;
  border-radius: 4px;
  margin: 10px 0;
}

.dimo-reconnect-message {
  background-color: #fff3e0;
  color: #e65100;
  padding: 10px 15px;
  border-radius: 4px;
  margin: 10px 0;
  font-weight: 500;
}

.provider-connection {
  margin-top: 15px;
}

.provider-select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.provider-connected {
  margin-top: 15px;
  padding: 10px;
  background-color: #e8f5e9;
  border-radius: 4px;
}

.telematics-note {
  grid-column: 1 / -1;
  background-color: #e3f2fd;
  padding: 15px;
  border-radius: 8px;
  margin-top: 20px;
}

.telematics-note p {
  margin: 0;
  color: #0d47a1;
} 