.auth-callback-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
}

.auth-callback-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 500px;
  text-align: center;
}

.auth-callback-card h2 {
  margin-bottom: 20px;
  color: #333;
}

.error-message {
  color: #e74c3c;
  margin-bottom: 20px;
}

.success-message {
  color: #2ecc71;
  margin-bottom: 20px;
}

.auth-callback-card button {
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;
  transition: background-color 0.3s;
}

.auth-callback-card button:hover {
  background-color: #357abd;
} 