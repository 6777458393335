/* Auth pages styling */
.login-page,
.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
}

.login-container,
.register-container {
  width: 100%;
  max-width: 480px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.logo-section {
  text-align: center;
  padding: 20px 0;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.logo-section h1 {
  margin: 0;
  color: #FFA500;
  font-size: 28px;
  font-weight: bold;
}

.login-card,
.register-card {
  padding: 24px;
}

.login-card h2,
.register-card h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  color: #212529;
  text-align: center;
}

.trial-notice,
.invite-notice {
  background-color: #e6f7ff;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #0066cc;
  text-align: center;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #495057;
}

.form-group input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.2s;
}

.form-group input:focus {
  border-color: #FFA500;
  outline: none;
}

.message {
  padding: 12px;
  margin-bottom: 16px;
  border-radius: 4px;
  font-size: 14px;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
}

.verification-section {
  margin-bottom: 16px;
  text-align: center;
}

.resend-button {
  background: none;
  border: none;
  color: #0066cc;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.auth-button {
  width: 100%;
  padding: 12px;
  background-color: #FFA500;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.auth-button:hover {
  background-color: #ff8c00;
}

.auth-button:disabled {
  background-color: #ffc966;
  cursor: not-allowed;
}

.auth-toggle {
  margin-top: 16px;
  text-align: center;
  font-size: 14px;
  color: #6c757d;
}

.toggle-button {
  background: none;
  border: none;
  color: #FFA500;
  font-weight: 500;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .login-container,
  .register-container {
    border-radius: 0;
    box-shadow: none;
  }
} 