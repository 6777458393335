.invite-members-container {
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.invite-members-container h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #333;
}

.invite-description {
  color: #666;
  margin-bottom: 1.5rem;
}

.invite-form {
  margin-bottom: 2rem;
}

.input-with-button {
  display: flex;
  gap: 10px;
}

.input-with-button input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.input-with-button input:focus {
  border-color: #f2c94c;
  outline: none;
  box-shadow: 0 0 0 2px rgba(242, 201, 76, 0.2);
}

.invite-button {
  padding: 0 20px;
  background-color: #f2c94c;
  color: #333;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.invite-button:hover {
  background-color: #e6b93b;
}

.invite-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.message {
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
}

.message.error {
  background-color: rgba(235, 87, 87, 0.1);
  color: #eb5757;
}

.message.success {
  background-color: rgba(39, 174, 96, 0.1);
  color: #27ae60;
}

.invitations-list {
  margin-top: 2rem;
}

.invitations-list h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #333;
  font-size: 1.2rem;
}

.no-invites {
  color: #888;
  font-style: italic;
}

.invitations-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.invite-item {
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.invite-email {
  font-weight: 600;
  color: #333;
}

.invite-status {
  background-color: rgba(242, 201, 76, 0.2);
  color: #333;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.invite-date {
  color: #888;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .input-with-button {
    flex-direction: column;
  }
  
  .invite-item {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Invitation link styling */
.invite-link {
  display: flex;
  width: 100%;
  margin: 10px 0;
  gap: 10px;
}

.invite-link input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #666;
  background-color: #f9f9f9;
}

.copy-button {
  background-color: #f2c94c;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.copy-button:hover {
  background-color: #e6b93b;
}

/* Company Users Styling */
.company-users {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.company-users h3 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #333;
  font-size: 1.2rem;
}

.no-users {
  color: #888;
  font-style: italic;
}

.users-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-item {
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.user-email {
  font-weight: 600;
  color: #333;
}

.current-user-badge {
  color: #666;
  font-style: italic;
  font-size: 0.9rem;
}

.admin-badge {
  background-color: #e0e0e0;
  color: #555;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.admin-badge.is-admin {
  background-color: rgba(242, 201, 76, 0.3);
  color: #333;
  font-weight: 600;
}

.user-actions {
  display: flex;
  gap: 8px;
}

.admin-toggle-button, .remove-user-button {
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.admin-toggle-button {
  background-color: #f2f2f2;
  color: #555;
}

.admin-toggle-button:hover {
  background-color: #e8e8e8;
}

.admin-toggle-button.make-admin {
  background-color: rgba(242, 201, 76, 0.2);
  color: #333;
}

.admin-toggle-button.make-admin:hover {
  background-color: rgba(242, 201, 76, 0.3);
}

.admin-toggle-button.remove-admin {
  background-color: rgba(245, 199, 88, 0.3);
  color: #333;
}

.admin-toggle-button.remove-admin:hover {
  background-color: rgba(245, 199, 88, 0.4);
}

.remove-user-button {
  background-color: rgba(235, 87, 87, 0.1);
  color: #eb5757;
}

.remove-user-button:hover {
  background-color: rgba(235, 87, 87, 0.2);
}

.admin-toggle-button:disabled, .remove-user-button:disabled {
  background-color: #f2f2f2;
  color: #bbb;
  cursor: not-allowed;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .user-item {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .user-actions {
    margin-top: 10px;
    width: 100%;
  }
  
  .admin-toggle-button, .remove-user-button {
    flex: 1;
  }
} 