.company-settings {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  max-width: 800px;
  margin: 0 auto;
}

.settings-description {
  color: #666;
  margin-bottom: 20px;
}

.company-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: 500;
  color: #333;
}

.form-group input,
.form-group select {
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.2s;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.submit-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 10px;
  align-self: flex-start;
}

.submit-button:hover {
  background-color: #3a7bc8;
}

.submit-button:disabled {
  background-color: #a0c0e4;
  cursor: not-allowed;
}

.message {
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
  margin: 10px 0;
}

.message.success {
  background-color: #e7f7ed;
  color: #28a745;
  border: 1px solid #c3e6cb;
}

.message.error {
  background-color: #f8d7da;
  color: #dc3545;
  border: 1px solid #f5c6cb;
}

@media (max-width: 768px) {
  .company-settings {
    padding: 15px;
  }
  
  .submit-button {
    width: 100%;
  }
} 