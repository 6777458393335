:root {
  --primary: #FDB813;
  --primary-dark: #e9a400;
  --primary-light: #ffd166;
  --secondary: #333333;
  --text: #333333;
  --text-light: #555555;
  --bg-light: #fffbf0;
  --white: #ffffff;
  --border: #e2e8f0;
  --success: #10b981;
  --warning: #f59e0b;
  --error: #ef4444;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #94a3b8;
  --gray-500: #64748b;
  --gray-600: #475569;
  --gray-700: #334155;
  --gray-800: #1e293b;
  --gray-900: #0f172a;
}

.communications-container {
  display: flex;
  height: calc(100vh - 80px);
  background-color: var(--bg-light);
  overflow: hidden;
}

/* Sidebar Styles */
.conversations-sidebar {
  width: 320px;
  background-color: var(--white);
  border-right: 1px solid var(--border);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--border);
}

.sidebar-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text);
  margin: 0;
}

.sidebar-actions {
  display: flex;
  gap: 8px;
}

.action-button {
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--text);
  transition: all 0.2s ease;
}

.action-button:hover {
  background-color: var(--gray-100);
}

.action-button.primary {
  background-color: var(--primary);
  color: var(--white);
  border: none;
}

.action-button.primary:hover {
  background-color: var(--primary-dark);
}

.conversation-list {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
}

.conversation-item {
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.conversation-item:hover {
  background-color: var(--gray-100);
}

.conversation-item.active {
  background-color: var(--primary-light);
}

.conversation-title {
  font-weight: 600;
  color: var(--text);
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.conversation-title svg {
  color: var(--primary);
}

.conversation-meta {
  font-size: 0.85rem;
  color: var(--text-light);
  display: flex;
  justify-content: space-between;
}

/* Chat Area Styles */
.chat-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-header {
  padding: 16px;
  border-bottom: 1px solid var(--border);
  background-color: var(--white);
  display: flex;
  align-items: center;
  gap: 12px;
}

.chat-header-icon {
  width: 40px;
  height: 40px;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
}

.chat-header-info {
  flex: 1;
}

.chat-header-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text);
  margin: 0;
}

.chat-header-subtitle {
  font-size: 0.85rem;
  color: var(--text-light);
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: var(--bg-light);
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 70%;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.message.received {
  align-self: flex-start;
}

.message.sent {
  align-self: flex-end;
  margin-left: auto;
}

.message-bubble {
  padding: 12px 16px;
  border-radius: 16px;
  position: relative;
}

.message.received .message-bubble {
  background-color: var(--white);
  border: 1px solid var(--border);
  border-bottom-left-radius: 4px;
}

.message.sent .message-bubble {
  background-color: var(--primary);
  color: var(--white);
  border-bottom-right-radius: 4px;
}

.message-meta {
  font-size: 0.75rem;
  margin-top: 4px;
  color: var(--gray-500);
}

.message.received .message-meta {
  text-align: left;
}

.message.sent .message-meta {
  text-align: right;
}

.message-form {
  padding: 16px;
  border-top: 1px solid var(--border);
  background-color: var(--white);
  display: flex;
  align-items: center;
  gap: 12px;
}

.message-input {
  flex: 1;
  border: 1px solid var(--border);
  border-radius: 24px;
  padding: 12px 16px;
  font-size: 1rem;
  resize: none;
  outline: none;
  min-height: 24px;
  max-height: 120px;
  transition: border-color 0.2s ease;
}

.message-input:focus {
  border-color: var(--primary);
}

.send-button {
  background-color: var(--primary);
  color: var(--white);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.send-button:hover {
  background-color: var(--primary-dark);
}

.send-button:disabled {
  background-color: var(--gray-300);
  cursor: not-allowed;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  padding: 16px;
  border-bottom: 1px solid var(--border);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text);
  margin: 0;
}

.modal-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--gray-500);
}

.modal-close:hover {
  color: var(--text);
}

.modal-body {
  padding: 16px;
}

.modal-footer {
  padding: 16px;
  border-top: 1px solid var(--border);
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.btn {
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-secondary {
  background-color: var(--white);
  border: 1px solid var(--border);
  color: var(--text);
}

.btn-secondary:hover {
  background-color: var(--gray-100);
}

.btn-primary {
  background-color: var(--primary);
  border: none;
  color: var(--white);
}

.btn-primary:hover {
  background-color: var(--primary-dark);
}

.form-group {
  margin-bottom: 16px;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--text);
}

.form-input {
  width: 100%;
  padding: 10px 12px;
  border-radius: 8px;
  border: 1px solid var(--border);
  font-size: 1rem;
}

.form-input:focus {
  outline: none;
  border-color: var(--primary);
}

.participant-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.participant-input {
  flex: 1;
}

.error-message {
  color: var(--error);
  margin-top: 8px;
  font-size: 0.9rem;
}

.tab-group {
  display: flex;
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--border);
}

.tab {
  flex: 1;
  padding: 12px;
  text-align: center;
  background-color: var(--white);
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.tab.active {
  background-color: var(--primary);
  color: var(--white);
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 24px;
  text-align: center;
  color: var(--gray-500);
}

.empty-state-icon {
  font-size: 3rem;
  margin-bottom: 16px;
  color: var(--gray-300);
}

.empty-state-text {
  margin-bottom: 16px;
  font-size: 1.1rem;
}

/* Loading Spinner */
.spinner {
  border: 3px solid var(--gray-200);
  border-top: 3px solid var(--primary);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive styles */
@media (max-width: 768px) {
  .communications-container {
    flex-direction: column;
    height: calc(100vh - 60px);
  }
  
  .conversations-sidebar {
    width: 100%;
    height: auto;
    max-height: 40%;
  }
  
  .message {
    max-width: 85%;
  }
} 