.personnel-upload {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  max-width: 900px;
  margin: 0 auto;
}

.upload-description {
  color: #666;
  margin-bottom: 20px;
}

.file-upload-container {
  background-color: #f9f9f9;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 30px;
}

.upload-options {
  display: flex;
  gap: 30px;
  margin-bottom: 15px;
}

.upload-option {
  flex: 1;
  min-width: 0;
}

.upload-option h4 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #333;
}

.upload-note {
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
}

.file-upload {
  margin-bottom: 15px;
}

.file-label {
  display: inline-block;
  background-color: #4a90e2;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.file-label:hover {
  background-color: #3a7bc8;
}

.file-label input {
  display: none;
}

.loading-indicator {
  margin-top: 10px;
  color: #4a90e2;
  font-weight: 500;
}

.upload-status {
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
  margin: 10px 0;
}

.upload-status.success {
  background-color: #e7f7ed;
  color: #28a745;
  border: 1px solid #c3e6cb;
}

.upload-status.error {
  background-color: #f8d7da;
  color: #dc3545;
  border: 1px solid #f5c6cb;
}

.personnel-list {
  margin-bottom: 30px;
}

.personnel-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.personnel-list th,
.personnel-list td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

.personnel-list th {
  background-color: #f8f9fa;
  font-weight: 600;
}

.personnel-list tr:hover {
  background-color: #f8f9fa;
}

.no-personnel {
  color: #6c757d;
  font-style: italic;
  margin-top: 15px;
}

.template-button, 
.add-button {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 10px;
}

.template-button:hover,
.add-button:hover {
  background-color: #218838;
}

.add-button {
  background-color: #4a90e2;
}

.add-button:hover {
  background-color: #3a7bc8;
}

.add-button:disabled {
  background-color: #a0c0e4;
  cursor: not-allowed;
}

/* Table row edit styles */
.personnel-list input,
.personnel-list select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.personnel-list input:focus,
.personnel-list select:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.action-buttons {
  display: flex;
  gap: 5px;
}

.edit-button, 
.save-button, 
.cancel-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-button {
  background-color: #4a90e2;
  color: white;
}

.edit-button:hover {
  background-color: #3a7bc8;
}

.save-button {
  background-color: #28a745;
  color: white;
}

.save-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.new-row {
  background-color: #f8f9fa;
}

@media (max-width: 768px) {
  .personnel-upload {
    padding: 15px;
  }
  
  .personnel-list {
    overflow-x: auto;
  }
  
  .file-label {
    width: 100%;
    text-align: center;
  }
  
  .template-button,
  .add-button {
    width: 100%;
  }
  
  .upload-options {
    flex-direction: column;
    gap: 20px;
  }
  
  .action-buttons {
    flex-direction: column;
    gap: 5px;
  }
} 