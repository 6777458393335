.join-company-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  min-height: 80vh;
  background-color: #f9f9f9;
}

.join-company-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  padding: 2rem;
  text-align: center;
}

.join-company-card h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #333;
}

.info-text {
  color: #666;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.error-message {
  background-color: rgba(235, 87, 87, 0.1);
  color: #eb5757;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
}

.join-button {
  padding: 14px 32px;
  background-color: #f2c94c;
  color: #333;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.join-button:hover {
  background-color: #e6b93b;
  transform: translateY(-2px);
}

.join-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  transform: translateY(0);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  font-size: 1.2rem;
  color: #333;
}

.success-card {
  border-left: 5px solid #27ae60;
}

.success-message {
  background-color: rgba(39, 174, 96, 0.1);
  color: #27ae60;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
} 