.vehicle-detail-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.page-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.back-button {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #495057;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
}

.back-button:hover {
  background-color: #e9ecef;
}

.vehicle-detail-page h1 {
  margin: 0;
  color: #333;
  flex-grow: 1;
}

.loading-container, 
.error-container, 
.not-found {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  margin-top: 30px;
}

.error-message {
  color: #dc3545;
  margin-bottom: 20px;
}

.error-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.error-actions button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.error-actions button:hover {
  background-color: #5a6268;
}

.vehicle-info-container,
.telemetry-container {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.vehicle-info-container h2,
.telemetry-container h2 {
  color: #333;
  margin-top: 0;
  margin-bottom: 20px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
}

.info-item {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.info-label {
  font-weight: bold;
  margin-right: 10px;
  color: #6c757d;
}

.info-value {
  color: #333;
}

.info-value.status {
  padding: 3px 8px;
  border-radius: 12px;
  font-size: 0.9em;
}

.info-value.status.active {
  background-color: #d4edda;
  color: #155724;
}

.info-value.status.inactive {
  background-color: #f8d7da;
  color: #721c24;
}

.telemetry-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.telemetry-header h2 {
  margin: 0;
}

.refresh-button {
  background-color: #17a2b8;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.refresh-button:hover {
  background-color: #138496;
}

.refresh-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.telemetry-loading,
.telemetry-error,
.no-telemetry {
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
}

.telemetry-error .error-message {
  margin-bottom: 10px;
}

.telemetry-timestamp {
  color: #6c757d;
  font-size: 0.9em;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.telemetry-timestamp .jwt-refreshed-indicator {
  margin-left: 10px;
  background-color: #ffc107;
  color: #212529;
  font-size: 0.85em;
  padding: 2px 6px;
  border-radius: 10px;
}

.telemetry-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.telemetry-item {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  transition: all 0.2s ease;
}

.telemetry-item:hover {
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.telemetry-item.location {
  grid-column: 1 / -1;
  background-color: #e9f7fe;
}

.telemetry-label {
  font-weight: bold;
  margin-right: 10px;
  color: #495057;
  display: block;
  margin-bottom: 5px;
}

.telemetry-value {
  color: #343a40;
  font-size: 1.1em;
  display: block;
}

/* JWT Refresh Button Styles */
.info-item.jwt-refresh {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.refresh-jwt-button {
  background-color: #6c5ce7;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  margin-left: auto;
}

.refresh-jwt-button:hover {
  background-color: #5f4dd1;
}

.refresh-jwt-button:disabled {
  background-color: #a29bfe;
  cursor: not-allowed;
  opacity: 0.7;
}

/* JWT Refresh Result Styles */
.jwt-refresh-result {
  grid-column: 1 / -1;
  margin-top: 10px;
  padding: 15px;
  border-radius: 6px;
  font-size: 0.9em;
}

.jwt-refresh-result.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.jwt-refresh-result.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.jwt-refresh-result p {
  margin-top: 0;
  margin-bottom: 10px;
}

.jwt-refresh-result .error-details {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
  font-family: monospace;
  font-size: 0.85em;
  max-height: 200px;
  white-space: pre-wrap;
}

/* Available Signals Styles */
.available-signals-container {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #dee2e6;
}

.available-signals-container h3 {
  font-size: 1.1rem;
  margin-bottom: 15px;
  color: #495057;
}

.available-signals {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.signal-tag {
  background-color: #e9ecef;
  color: #495057;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.85rem;
  border: 1px solid #dee2e6;
}

/* Debug section styles */
.debug-section {
  margin-top: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-family: monospace;
  font-size: 0.9em;
}

.debug-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.error-title {
  color: #721c24;
  margin-bottom: 10px;
}

.no-data-message {
  padding: 20px;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 4px;
  color: #856404;
  margin-top: 15px;
} 